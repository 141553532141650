import { eui } from "@/lib/eui"

class WSRequest {
  constructor(url) {
    this.ws = null
    // this.url = url
    this.userId = null
    this.timeout = 10 * 1000

    // 请求队列
    this.maxLength = 20

    // 响应对象
    this.responseMap = new Map()

    this._timer = null

    this.timerIds = new Map()

    // url设置
    if (Array.isArray(url) && url.length > 0) {
      this.url = url[0]
      this.altUrls = url
    } else {
      this.url = url
      this.altUrls = [url]
    }
    this.altIndex = 0
  }

  get is_open() {
    if (this.ws) {
      return this.ws.readyState === WebSocket.OPEN
    }
    return false
  }

  request(method, params) {
    return new Promise((resolve, reject) => {
      console.log('send -> ' + method)
      if (!method) {
        throw new Error('需要业务类型方法')
      }

      const data = {
        method: method,
        params: {},
        timestamp: Date.now(),
      }

      if (params != null && typeof params === 'object') {
        data.params = params
      }

      this.send(data)

      let reqKey = this.getKey(data)

      // 超时时直接reject
      let t = setTimeout(() => {
        reject(new Error('获取数据超时！'))

        clearInterval(this.timerIds.get(reqKey))
        // remove
      }, this.timeout)

      let timer = setInterval(() => {
        this._runCheck(reqKey, (data) => {
          clearTimeout(t)
          console.log('receive <-- ' + data.method)
          resolve(data.data)
        })
      }, 10)

      this.timerIds.set(reqKey, timer)
    })
  }

  _runCheck(key, successCallback) {
    if (this.responseMap.has(key)) {
      let res = this.responseMap.get(key)
      //
      successCallback(res)
      // remove
      // 得到数据时清除计时
      clearInterval(this.timerIds.get(key))
      this.responseMap.delete(key)
      this.timerIds.delete(key)
    }

  }

  getKey(RObj) {
    return RObj.method + '-' + RObj.timestamp
  }

  send(obj) {
    if (this.is_open) {
      let str = JSON.stringify(obj)
      this.ws.send(str)
    } else {
      console.error('请在WebSocket链接后再发送数据')
    }
  }
  connect(userId = '') {
    this.userId = userId
    // console.log('[ws-url] ' + this.url + userId)
    this.ws = new WebSocket(this.url + userId)

    this.connectCount = 0
    this.connectTimer = null
    this.addMessageListener()
    this.addErrorListener()

  }
  reconnect() {
    // 切换备用url
    this.url = this.altUrls[this.altIndex++ % this.altUrls.length]
    
    this.ws = new WebSocket(this.url + this.userId)
    this.addMessageListener()
    this.addErrorListener()
  }
  close() {
    this.ws && this.ws.close()
  }

  addMessageListener() {
    this.ws.addEventListener('message', e => {
      const result = JSON.parse(e.data)
      if (result.code == 200) {
        let key = this.getKey(result)
        this.responseMap.set(key, result)
      } else {
        eui.showTopTip(result.msg || result.message, 'error')
      }
    })
  }

  addErrorListener() {

    const tryConnect = () => {
      if (this.connectCount >= 5) {
        clearTimeout(this.connectTimer)
        return
      }
      this.connectTimer = setTimeout(() => {
        console.warn('[ws] 正在重连...')
        this.reconnect(this.userId)
        this.connectCount++
      }, 500)
    }

    this.ws.addEventListener('error', (event) => {
      console.error('ws连接出错')
    })

    this.ws.addEventListener('close', (event) => {
      console.error('ws连接已断开')
      tryConnect()
    })
  }


  /**
   * 增加备用url
   * @param {*} url 
   */
  addAltUrl(url) {
    this.altUrls.push(url)
  }
}



// 生产(wss)
// const ws_req = new WSRequest(`wss://www.emind2000.cloud:8190/dashboard/websocket/message/`)
// 生产ws
// const ws_req = new WSRequest(`ws://120.25.246.122:8088/dashboard/websocket/message/`)


// 开发(wss)
// const ws_req = new WSRequest(`wss://dev.jd-energy.com.cn:8191/dashboard/websocket/message/`)
// const ws_req = new WSRequest(`ws://192.168.0.61:12000/dashboard/websocket/message/`)

// 测试(wss)
var jd_ws_config = {
  "prod": "ws://120.25.246.122:8088/dashboard/websocket/message/",
  "prod-s": "wss://www.emind2000.cloud:8190/dashboard/websocket/message/",

  "dev": "ws://192.168.0.230:12000/dashboard/websocket/message/",
  "dev-s": "wss://dev.jd-energy.com.cn:8190/dashboard/websocket/message/",

  "test": "ws://192.168.0.234:12000/dashboard/websocket/message/",
  "test-s": "wss://test.jd-energy.com.cn:8290/dashboard/websocket/message/"
}

const ws_req = new WSRequest(jd_ws_url||jd_ws_config[jd_ws_key])
// 测试(ws)
// const ws_req = new WSRequest(`ws://192.168.0.234:12000/dashboard/websocket/message/`)





export { ws_req }
