import { defineStore } from "pinia"
import Cookies from 'js-cookie'
import { createStorage, unwrap } from '../../utils'
import { nameList } from '../../api/device/station'
import { listModel } from "@/api/device/model"


const local_storage = createStorage('localStorage')

const useAppStore = defineStore(
  'app',
  {
    state: () => ({
      sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false,
        hide: false
      },
      device: 'desktop',
      size: Cookies.get('size') || 'default',
      stations: local_storage.load('stations') || [],
      stationId: local_storage.load('stationId'),
      modelList: [],
    }),
    getters: {
      currentStation: (state) => {
        return state.stations.find(item => state.stationId == item.stationId)  
      },
      stationName: (state) => {
        return state.currentStation?.name
      },
      strategySwitch: (state) => {
        return state.currentStation?.strategySwitch
      },
      incomeModel:  (state) => {
        return state.currentStation?.incomeModel
      },
      commossioningTime:  (state) => {
        return state.currentStation?.commossioningTime
      },

      eBlockModels() {
        return this.filterModels('eBlock')
      },
      eLinkModels() {
        return this.filterModels('eLink')
      },
    },
    actions: {
      getMeterModel(sid) {
        let station = this.stations.find(item => sid == item.stationId)
        return station?.meterModel
      },
      toggleSideBar(withoutAnimation) {
        if (this.sidebar.hide) {
          return false;
        }
        this.sidebar.opened = !this.sidebar.opened
        this.sidebar.withoutAnimation = withoutAnimation
        if (this.sidebar.opened) {
          Cookies.set('sidebarStatus', 1)
        } else {
          Cookies.set('sidebarStatus', 0)
        }
      },
      closeSideBar(withoutAnimation) {
        Cookies.set('sidebarStatus', 0)
        this.sidebar.opened = false
        this.sidebar.withoutAnimation = withoutAnimation
      },
      toggleDevice(device) {
        this.device = device
      },
      setSize(size) {
        this.size = size;
        Cookies.set('size', size)
      },
      toggleSideBarHide(status) {
        this.sidebar.hide = status
      },
      getStations() {
        nameList().then(res => {
          this.stations = res.data
          local_storage.save('stations', res.data)

          if (!this.stationId) {
            if (res.data.length > 0) {
              this.setStationId(res.data[0].stationId)
            }
          }
        })
      },
      
      setStationId(id) {
        this.stationId = id
        local_storage.save('stationId', id)
      },
      
      async getModelList() {
        let modelList = await unwrap(listModel)({
          pageNum: 1,
          pageSize: 65535,
        })
        this.modelList = modelList
      },

      filterModels(modelType) {
        return this.modelList.filter(item => {
          return item.modelType == modelType
        })
      },
    }
  })

export default useAppStore
