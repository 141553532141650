import { defineStore } from "pinia"
import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import defAva from '@/assets/images/profile.png'
import defLogo from '@/assets/logo/logo.png'
import defVisual from '@/assets/logo/visual.png'
import { ws_req } from '../../ws/wss-request'
// import { ws_connect } from '@/ws/ws_req'
import { createStorage } from '@/utils'

const local_storage = createStorage('localStorage')
const session_storage = createStorage('sessionStorage')

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      name: '',
      avatar: '',
      admin: '',
      logo: '',
      visual: '',
      roles: [],
      permissions: [],
      isShowGemini:false,
    }),
    actions: {
      // 登录
      login(userInfo) {
        const username = userInfo.username.trim()
        const password = userInfo.password
        const code = userInfo.code
        const uuid = userInfo.uuid
        return new Promise((resolve, reject) => {
          login(username, password, code, uuid).then(res => {
            setToken(res.data.access_token)
            this.token = res.data.access_token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          const params = {
            systemSign: 'eMind',
          }
          getInfo(params).then(res => {

            ws_req.connect(res.user.userId)
            // ws_connect(res.user.userId)
            console.warn('[ws-req] connect');

            const user = res.user
            // import.meta.env.VITE_APP_BASE_API
            const avatar = (user.avatar == "" || user.avatar == null) ? defAva : user.avatar;
            const logo = (user.logo == "" || user.logo == null) ? defLogo : user.logo;
            const visual = (user.visualLogo == "" || user.visualLogo == null) ? defVisual : user.visualLogo;

            if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              this.roles = res.roles
              this.permissions = res.permissions
            } else {
              this.roles = ['ROLE_DEFAULT']
            }
            this.name = user.userName
            
            this.admin = user.admin
            this.avatar = avatar;
            this.logo = logo;
            this.visual = visual;
            res.systems.forEach(item=>{
              if(item.systemSign === 'eMind-user'){
                this.isShowGemini = true;
                return;
              }
            })
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          logout(this.token).then(() => {
            ws_req.close()
            console.warn('[ws-req] close')

            this.token = ''
            this.roles = []
            this.permissions = []
            removeToken()
            resolve()
            local_storage.clear()
            session_storage.clear()
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  })

export default useUserStore
